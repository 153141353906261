// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4db6a4", // Example: Green for primary (can be one of your green shades)
    },
    secondary: {
      main: "#034f4d", // Example: Orange for secondary
    },
    background: {
      default: "#F9F7F1", // Primary text color
    },
    platinum: {
      primary: "#e5e4e2", // Primary text color
    },
    subheading: {
      primary: "red", // Secondary text color
    },
    text: {
      primary: "#000000", // Black text
      secondary: "#808080", // Dark gray for secondary text
      thirdary: "#000000", // White
    },
  },
  typography: {
    fontFamily: "Poppins", // Set your global font here
    h1: {
      fontFamily: "Poppins", // Optional: Customize specific styles
    },
    h2: {
      fontFamily: "Poppins",
    },
    body1: {
      fontFamily: "Poppins",
    },
  },
});

export default theme;
