// src/Navbar.js
import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";

const Navbar = () => {
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        px: { xs: 2, sm: 4, md: 6, lg: 8 }, // Responsive padding
        py: 2,
        borderBottom: "1px solid #e5e4e2",
        marginBottom: 8,
        backgroundColor: "#F9F7F1",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "center", // Center content on small screens
          flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on small screens
          alignItems: "center",
          padding: "0",
        }}
        disableGutters
      >
        {/* Logo */}
        <IconButton edge="start" color="inherit" aria-label="logo">
          <img
            src="/ardiLogo.png"
            alt="Ardi Logo"
            style={{
              width: "100px", // Responsive logo width
              height: "auto", // Maintain aspect ratio
            }}
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
